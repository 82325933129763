import {gql} from "@apollo/client";
import {USER_FRAGMENT} from "../../users/graphql/user";
import {PRODUCT_FRAGMENT} from "../../products/graphql/ProductFragment";


export const ORDER_FRAGMENT = gql`
    fragment OrderFragment on Order {
        id
        user_id
        seen
        code
        status
        full_price
        discount
        commercial
        message
        delivery_method
        payment_method
        created_at
    }
`

export const GET_ORDER_BY_CODE_QUERY = "getOrderByCode";
export const GET_ORDER_BY_CODE = gql`
    ${ORDER_FRAGMENT}
    ${PRODUCT_FRAGMENT}
    ${USER_FRAGMENT}
    query ($code: String!) {
        getOrderByCode(code: $code) {
            ...OrderFragment
            user {
                ...UserFragment
            }
            products {
                ...ProductFragment
                orderPivot {
                    id
                    qty
                    price
                    orig_price
                }
            }
        }
    }
`

export const GET_ORDERS_QUERY = "getOrders";
export const GET_ORDERS = gql`
    ${ORDER_FRAGMENT}
    ${USER_FRAGMENT}
    ${PRODUCT_FRAGMENT}
    query ($seen: Boolean) {
        getOrders(seen: $seen) {
            data    {
                ...OrderFragment
                user {
                   ...UserFragment 
                }
                products {
                    ...ProductFragment
                    orderPivot {
                        id
                        qty
                        price
                        orig_price
                    }
                }
            }
            paginatorInfo {
                currentPage
                lastPage
                count
                total
            }
        }
    }
`
export const GET_USER_ORDERS_QUERY = "getUserOrders";
export const GET_USER_ORDERS = gql`
    ${ORDER_FRAGMENT}
    query {
        getUserOrders {
            data    {
                ...OrderFragment
            }
            paginatorInfo {
                currentPage
                lastPage
                count
                total
            }
        }
    }
`

export const PLACE_ORDER_MUTATION = "placeOrder";
export const PLACE_ORDER = gql`
    mutation ($basket: String!, $message: String)  {
        placeOrder(basket: $basket, message: $message) {
            error
            code
        }
    }
`

export const UPDATE_ORDER = gql`
    ${ORDER_FRAGMENT}
    mutation ($id: ID!, $seen: Boolean, $status: Boolean)  {
        updateOrder(id: $id, seen: $seen, status: $status) {
            ...OrderFragment
        }
    }
`