import React from "react";
import {useQuery} from "@apollo/client";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import Layout from "../layout/Layout";
import {GET_SELF, GET_SELF_QUERY} from "../../graphql/user";



const Discount = (props) => {
    const {data, loading, error} = useQuery(GET_SELF, {
        fetchPolicy: "network-only"
    });
    if(loading) return <Layout><Loader /></Layout>
    if(error) return <Layout><GraphQLErrorMessage error={error} /></Layout>
    const user = data[GET_SELF_QUERY];

    return (
        <Layout>
            <div className="bg-white p-4 h4">
                Rabat je: {user.discount || 0}  %
            </div>

        </Layout>
    )
}

export default Discount;
