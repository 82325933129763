import {Link} from "react-router-dom";
import {ChevronDown, ChevronRight} from "react-feather";
import {useState} from "react";
import {route, widget} from "../../../modulesCompiler";

function CategoryItem({item, close}) {
    const [open, setOpen] = useState(false);

    const toggle = (e) => {
        e.preventDefault();
        setOpen(!open);
    }

    return (
        <>
            {item.children.length > 0
                ?   <>
                    <Link onClick={toggle} to={route("products.shop", item)}
                              className="d-flex align-items-center fw-bold font_15">
                        {item.extend?.image &&
                        <img src={widget("storage.image", item.extend.image)} width={22} alt={item.name} />}
                        <div className="flex-grow-1 ms-2">
                            {item.name}
                        </div>
                        {open ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
                    </Link>
                    </>
                :   <Link to={route("products.shop", item)}
                          className="d-flex align-items-center fw-bold font_15">
                    {item.extend?.image &&
                    <img src={widget("storage.image", item.extend.image)} width={22} alt={item.name} />}
                    <div className="flex-grow-1 ms-2">
                        {item.name}
                    </div>
                </Link>}

            <div className={open ? "subcat subcat_active" : "subcat"}>
                {item.children.map(child => {
                    return <div key={child.id}>
                        <Link onClick={close}  to={route("products.shop", child)}
                              className="d-flex justify-content-between align-items-center font_15">
                            <div className="ms-3">
                                <span /> {child.name}
                            </div>
                        </Link>
                    </div>
                })}
            </div>
        </>
    )
}
export default CategoryItem;