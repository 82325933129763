import CategorySelect from "./widgets/CategorySelect";
import CategoriesDrawer from "./widgets/CategoriesDrawer/CategoriesDrawer";
import useGetBreadcrumbsBySlug from "./widgets/useGetBreadcrumbsBySlug";
import Categories from "./views/categories/Categories";

const routes = {
    categories: {path: "/cms/kategorije", component: Categories, middleware: 'cms.AdminAuthenticatedMiddleware'},
};
const cmsLeftMenu = [
    {path: routes.categories.path, name: "Kategorije", position: 3},
];


const exports = {
    routes: routes,
    cmsLeftMenu: cmsLeftMenu,
    widgets: {
        CategorySelect: CategorySelect,
        CategoriesDrawer: CategoriesDrawer,
        useGetBreadcrumbsBySlug: useGetBreadcrumbsBySlug,

    }
}
export default exports;