import BasketContext from "../contexts/BasketContext";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
    CREATE_BASKET,
    DELETE_BASKET,EMPTY_BASKET,
    GET_BASKET,
    GET_BASKET_QUERY,
    UPDATE_BASKET
} from "../graphql/basket";
import toast from "react-hot-toast";


function GlobalBasketWrapper({children})    {
    const [create] = useMutation(CREATE_BASKET);
    const [upd] = useMutation(UPDATE_BASKET);
    const [del] = useMutation(DELETE_BASKET);
    const [empt] = useMutation(EMPTY_BASKET);
    const [getBasket, {data, refetch}] = useLazyQuery(GET_BASKET);
    const basket = data ? data[GET_BASKET_QUERY] : [];

    const total = () => {
        let holder = 0;
        for(let i=0; i<basket.length; i++)  {
            if(!basket[i].product.process_price) continue;
            holder += (basket[i].product.process_price * basket[i].qty);
        }
        return holder;
    }
    const add = (item, qty) => {
        if(!qty) qty = 1;
        qty = parseInt(qty);
        create({variables: {product_id: item.id, qty: qty}}).then(response => {
            refetch();
            toast.success("Dodato u korpu!");
        }).catch(error => {
            toast.error(error.message);
        });
    }
    const remove = (id) => {
        const find = basket.find(item => item.product.id === id);
        if(!find)   return false;
        del({variables: {id: find.id}}).then(response => {
            refetch();
            toast.success("Uklonjeno iz korpe!");
        }).catch(error => {
            toast.error(error.message);
        });
    }
    const empty = () => {
        const ids = basket.map(item => item.id);
        empt({variables: {ids: ids}}).then(response => {
            refetch();
            toast.success("Uklonjeno iz korpe!");
        }).catch(error => {
            toast.error(error.message);
        });
    }
    const removeByBasket = (id) => {
        del({variables: {id: id}}).then(response => {
            refetch();
            toast.success("Uklonjeno iz korpe!");
        }).catch(error => {
            toast.error(error.message);
        });
    }
    const update = (id, qty) => {
        if(!qty) {
            remove(id);
            return;
        }
        const find = basket.find(item => item.product.id === id);
        if(!find) return;
        qty = parseInt(qty);
        upd({variables: {id: find.id, qty: qty}});
        refetch();
    }


    return <BasketContext.Provider value={{basket, total, add, remove, empty, removeByBasket, update, getBasket}}>
        {children}
    </BasketContext.Provider>
}
export default GlobalBasketWrapper;