import {route, trans, widget} from "../../../modulesCompiler";
import Loader from "../../../../app/Loader";
import ShopProducts from "./ShopProducts";
import {Link} from "react-router-dom";
import {seoDescription, seoKeywords, seoTitle} from "../../../../app/seoProvider";

function Shop(props) {
    let categorySlug = props.computedMatch.params.cat1;
    const shopBreadcrumbs = {trans: trans("shop"), route: route("products.shop")};

    if(!categorySlug)   {
        return  <div>
            {widget("public.Breadcrumbs", {links: [shopBreadcrumbs]})}
            <div className="container mb-6">
                <ShopProducts count={5} />
            </div>
        </div>
    }

    const {breadcrumbs, loading} = widget("categories.useGetBreadcrumbsBySlug", categorySlug);
    if(loading) return <Loader opacity={.4} />;

    if(breadcrumbs.length === 0)  {
        return  <div>
            {widget("public.Breadcrumbs", {links: [shopBreadcrumbs]})}
            <div className="container mb-6">
                <ShopProducts count={5} />
            </div>
        </div>
    }

    const bread = breadcrumbs.map(item => {
        return {trans: item.name, route: route("products.shop", item)}
    });
    const parentCategory = breadcrumbs[0];
    const category = breadcrumbs[breadcrumbs.length-1];
    let categoriesSearch = category ? [category.id] : undefined;
    if(category.id === parentCategory.id)       {
        categoriesSearch = parentCategory.children.map(item => item.id);
    }
    if(category)    {
        seoTitle(category.extend?.seo_title);
        seoKeywords(category.extend?.seo_keywords);
        seoDescription(category.extend?.seo_description);
    }

    return <div className="mb-6">
        {widget("public.Breadcrumbs", {links: [shopBreadcrumbs, ...bread]})}

        <div className="container">
            <div className="row g-md-5">
                <div className="col-md-3">
                    <div className="bg-white rounded-3 pt-4 pb-4 ps-4 d-none d-md-block" style={{marginTop: 52}}>
                        <div className="text-black-50 mb-3 fw-bold">{parentCategory.name}</div>

                        {parentCategory.children && parentCategory.children.map(item => {
                            return <div key={item.id} className="link_gray weight_500">
                                <Link to={route("products.shop", item)}
                                      className={`${categorySlug === item.id ? "text-secondary" : ""} pt-2 pb-2 ps-4 d-inline-block w-100`}>
                                    {item.name}
                                </Link>
                            </div>
                        })}
                    </div>
                </div>
                <div className="col-md-9">
                    <ShopProducts count={4} category={categoriesSearch} />
                </div>
            </div>
        </div>
    </div>
}
export default Shop;