import GlobalSettingsWrapper from "./middlewares/GlobalSettingsWrapper";
import useGetSetting from "./widgets/useGetSetting";
import FieldUpdate from "./widgets/FieldUpdate";


const exports = {
    wrappers: [GlobalSettingsWrapper],
    widgets: {
        useGetSetting: useGetSetting,
        FieldUpdate: FieldUpdate
    }

}
export default exports;