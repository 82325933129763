import {Link} from "react-router-dom";
import React, {useRef} from "react";
import {route, trans} from "../../modulesCompiler";
import {checkNoRabat, dateTime, formatPrice} from "../../../app/helpers";
import {Printer} from "react-feather";
import ReactToPrint from "react-to-print";


class ComponentToPrint extends React.Component {
    render = () => {
        const order = this.props.order;
        const currency = this.props.currency;
        return (
                <div id="printable" className="d-none">
                    <div className="container mt-4">
                        <h4 className="text-center fw-bold mb-3">Barok 032</h4>
                        <div className="row mb-3">
                            <div style={{width: "33%"}} className="col-md-4">
                                <div className="fw-bold mb-3 border-bottom"> Osnovno </div>
                                <div className="mb-1">Šifra</div>
                                <div className="mb-3">
                                    <strong>#{order.code}</strong>
                                </div>
                                <div className="mb-1">Cena</div>
                                <div className="mb-3">
                                    <div><strong>{formatPrice(order.full_price)} {currency}</strong></div>
                                </div>
                                <div className="mb-1">Rabat</div>
                                <div className="mb-3">
                                    <div><strong>{order.discount || 0}%</strong></div>
                                </div>
                                <div className="mb-1">Datum</div>
                                <div className="mb-3">
                                    <strong>{dateTime(order.created_at)}</strong>
                                </div>
                            </div>

                            <div style={{width: "33%"}} className="col-md-4">
                                <div className="fw-bold mb-3 border-bottom"> Kontakt </div>
                                <div className="mb-1">Ime i prezime</div>
                                <div className="mb-3">
                                    <strong>{order.user.name} {order.user.lastname}</strong>
                                </div>

                                <div className="mb-1">Telefon</div>
                                <div className="mb-3">
                                    <strong>{order.user.phone}</strong>
                                </div>
                                <div className="mb-1">Email</div>
                                <div className="mb-3">
                                    <strong>{order.user.email}</strong>
                                </div>
                            </div>

                            <div style={{width: "33%"}} className="col-md-4">
                                <div className="fw-bold mb-3 border-bottom"> Firma </div>
                                <div className="mb-1">Ime</div>
                                <div className="mb-3">
                                    <strong>{order.user.firm_name}</strong>
                                </div>
                                <div className="mb-1">Adresa</div>
                                <div className="mb-3">
                                    <strong>{order.user.firm_address}</strong>
                                </div>
                                <div className="mb-1">Mesto</div>
                                <div className="mb-3">
                                    <strong>{order.user.firm_place}</strong>
                                </div>
                                <div className="mb-1">PIB</div>
                                <div className="mb-3">
                                    <strong>{order.user.firm_pib}</strong>
                                </div>
                                <div className="mb-1">Matični broj</div>
                                <div className="mb-3">
                                    <strong>{order.user.firm_number}</strong>
                                </div>
                            </div>
                        </div>

                        <table className="table table-bordered bg-white">
                            <thead>
                            <tr className="fw-bold">
                                <td style={{width: 40}}>Br.</td>
                                <td>Artikal</td>
                                <td style={{width: 80}}>Šifra</td>
                                <td>Cena</td>
                                <td style={{width: 50}}>Kol.</td>
                                <td style={{width: 50}}>Rabat</td>
                                <td style={{width: 50}}>PDV</td>
                                <td style={{width: 160}}>UKUPNO</td>
                            </tr>
                            </thead>
                            <tbody>
                            {order.products.map((product, index) => {
                                return <tr key={product.orderPivot.id}>
                                    <td className="align-middle">{index + 1}.</td>
                                    <td className="fw-bold align-middle">
                                        <div>
                                            <strong>{product.name}</strong>
                                        </div>
                                        <div className="fw-normal">
                                            <span className="fs-s"> Količina u paketu: </span> {product.komadaUPaketu} <br/>
                                            <span className="fs-s"> Jedinica mere: </span> {product.jedinicaMere} <br/>
                                            <span className="fs-s"> Stanje: </span> {product.qty < 100 ? product.qty : "100+"}
                                        </div>
                                    </td>
                                    <td className="align-middle">{product.code}</td>

                                    <td className="align-middle">
                                        <div>
                                            Cena proizvoda: <br />
                                            <strong>{formatPrice(product.orderPivot.orig_price)} {currency}</strong>
                                        </div>
                                        Poručena cena: <br />
                                        <strong>{formatPrice(product.orderPivot.price)} {currency}</strong>
                                        {checkNoRabat(product.badges) &&
                                        <div className="fst-italic fs-s fw-normal">
                                            {checkNoRabat(product.badges).opis}
                                        </div>}
                                    </td>
                                    <td className="align-middle">
                                        <strong>{product.orderPivot.qty}</strong>
                                    </td>
                                    <td className="align-middle">
                                        {checkNoRabat(product.badges)
                                            ? <span> 0% </span>
                                            : <span> {order.discount || 0}% </span>}
                                    </td>
                                    <td className="align-middle">
                                        {product.pdv}%
                                    </td>
                                    <td className="align-middle">
                                        <div className="mb-1">Ukupno:</div>
                                        <div className="mb-3">
                                            <strong>
                                                {formatPrice(product.orderPivot.price * product.orderPivot.qty)} {currency}
                                            </strong>
                                        </div>
                                    </td>
                                </tr>
                            })}
                            <tr className="fw-bold fs-5">
                                <td colSpan={8} className="p-3 text-end">
                                    Ukupno:  {formatPrice(order.full_price)} {currency}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

        )
    }
}

function OrderRender({order}) {
    const currency = trans("currency");
    const componentRef = useRef();

    return (
        <>
            <div className="mb-3">
                <ReactToPrint
                    trigger={() =><button className="btn btn-light"><Printer /> Odštampajte</button>}
                    content={() => componentRef.current}
                    documentTitle={`Porudžbina #${order.code} - Barok 032`}
                />
                <ComponentToPrint ref={componentRef} order={order} currency={currency} />
            </div>
        <div>
            {/*<div className="mb-4 fs-4" style={{color: status.color}}>
                <strong> Status: {status.title} </strong>
            </div>*/}

            <div className="bg-white border mb-4 p-3">

                <div className="row mb-3">
                    <div className="col-md-4">
                        <div className="fw-bold mb-3 border-bottom"> Osnovno </div>
                        <div className="mb-1">Šifra</div>
                        <div className="mb-3">
                            <strong>#{order.code}</strong>
                        </div>
                        <div className="mb-1">Cena</div>
                        <div className="mb-3">
                            <div><strong>{formatPrice(order.full_price)} {currency}</strong></div>
                        </div>
                        <div className="mb-1">Rabat</div>
                        <div className="mb-3">
                            <div><strong>{order.discount || 0}%</strong></div>
                        </div>
                        <div className="mb-1">Datum</div>
                        <div className="mb-3">
                            <strong>{dateTime(order.created_at)}</strong>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="fw-bold mb-3 border-bottom"> Kontakt </div>
                        <div className="mb-1">Ime i prezime</div>
                        <div className="mb-3">
                            <strong>{order.user.name} {order.user.lastname}</strong>
                        </div>

                        <div className="mb-1">Telefon</div>
                        <div className="mb-3">
                            <strong>{order.user.phone}</strong>
                        </div>
                        <div className="mb-1">Email</div>
                        <div className="mb-3">
                            <strong>{order.user.email}</strong>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="fw-bold mb-3 border-bottom"> Firma </div>
                        <div className="mb-1">Ime</div>
                        <div className="mb-3">
                            <strong>{order.user.firm_name}</strong>
                        </div>
                        <div className="mb-1">Adresa</div>
                        <div className="mb-3">
                            <strong>{order.user.firm_address}</strong>
                        </div>
                        <div className="mb-1">Mesto</div>
                        <div className="mb-3">
                            <strong>{order.user.firm_place}</strong>
                        </div>
                        <div className="mb-1">PIB</div>
                        <div className="mb-3">
                            <strong>{order.user.firm_pib}</strong>
                        </div>
                        <div className="mb-1">Matični broj</div>
                        <div className="mb-3">
                            <strong>{order.user.firm_number}</strong>
                        </div>
                    </div>

                </div>

                {order.message &&
                <div className="p-3 mb-3 bcg-2">
                    <div className="fw-bold mb-3 border-bottom"> Dodatna napomena </div>
                    <div className="prewrap">{order.message}</div>
                </div>}
            </div>

            <div className="table-responsive">
                <table className="table table-bordered bg-white">
                    <thead>
                    <tr className="fw-bold">
                        <td style={{width: 50}}>Br.</td>
                        <td style={{minWidth: 140}}>Slika</td>
                        <td style={{minWidth: 240}}>Artikal</td>
                        <td>Šifra</td>
                        <td style={{minWidth: 320}}>Cena</td>
                        <td style={{width: 100}}>Kol.</td>
                        <td style={{width: 80}}>Rabat</td>
                        <td style={{width: 80}}>PDV</td>
                        <td>UKUPNO</td>
                    </tr>
                    </thead>
                    <tbody>
                    {order.products.map((product, index) => {
                        return <tr key={product.orderPivot.id}>
                            <td className="align-middle">{index + 1}.</td>
                            <td>
                                <div className="bcg_image" style={{backgroundImage: `url(https://barok032.rs/prod-images/m/${product.id}.jpg)`}} />
                            </td>
                            <td className="fw-bold align-middle">
                                <Link to={route("products.shopProduct", product)}>
                                    <strong>{product.name}</strong>
                                </Link>
                                <div className="fw-normal">
                                    <span className="fs-s"> Količina u paketu: </span> {product.komadaUPaketu} <br/>
                                    <span className="fs-s"> Jedinica mere: </span> {product.jedinicaMere} <br/>
                                    <span className="fs-s"> Stanje: </span> {product.qty < 100 ? product.qty : "100+"}
                                </div>
                            </td>
                            <td className="align-middle"><b>{product.code}</b></td>

                            <td className="align-middle">
                                <div>
                                    Cena proizvoda: <strong>{formatPrice(product.orderPivot.orig_price)} {currency}</strong>
                                </div>
                                Poručena cena: <strong>{formatPrice(product.orderPivot.price)} {currency}</strong>
                                <span className="text-black-50 ms-1">(+rabat +pdv)</span>
                                {checkNoRabat(product.badges) &&
                                <div className="fst-italic fs-s fw-normal">
                                    {checkNoRabat(product.badges).opis}
                                </div>}
                            </td>
                            <td className="align-middle">
                                <div className="mb-1">Količina:</div>
                                <div className="mb-3">
                                    <strong>{product.orderPivot.qty}</strong>
                                </div>
                            </td>
                            <td className="align-middle">
                                {checkNoRabat(product.badges)
                                    ? <span> 0% </span>
                                    : <span> {order.discount || 0}% </span>}
                            </td>
                            <td className="align-middle">
                                {product.pdv}%
                            </td>
                            <td className="align-middle">
                                <div className="mb-1">Ukupno:</div>
                                <div className="mb-3">
                                    <strong>
                                        {formatPrice(product.orderPivot.price * product.orderPivot.qty)} {currency}
                                    </strong>
                                </div>
                            </td>
                        </tr>
                    })}
                    <tr className="fw-bold fs-5">
                        <td colSpan={8} className="p-3 text-end"> Ukupno: </td>
                        <td width={180} className="p-3">
                            {formatPrice(order.full_price)} {currency}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>
        </>
    )
}
export default OrderRender;