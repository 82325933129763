import {Link} from "react-router-dom";
import {route, trans, widget} from "../../modulesCompiler";
import {checkNoRabat, formatPrice} from "../../../app/helpers";


function ProductRender({item}) {

    return (
        <div className="bg-white p-3 rounded-3 position-relative">
            <div className="d-flex justify-content-between align-items-baseline position-absolute"
                 style={{top: 8, left: 8, right: 8, zIndex: 10}}>
                <div>
                    {item.meniId === 2 &&
                    <div className="bg-danger text-center fw-bold fs-s text-white rounded-2 p-1 ps-2 pe-2 mb-2">
                        AKCIJA
                    </div>}

                    {checkNoRabat(item.badges) &&
                    <div title={checkNoRabat(item.badges).opis} className="bg-danger fw-bold fs-s text-white rounded-2 p-1 ps-2 pe-2">
                        0 RABAT
                    </div>}
                </div>

                {item.vreme > 0 &&
                <div className="bg-success fw-bold fs-s text-white rounded-2 p-1 ps-2 pe-2">
                    NOVO
                </div>}
            </div>
            <Link to={route("products.shopProduct", item)}>
                <div className="text-center mb-3 img_overlay">
                    {/*<div className="bcg_image" style={{backgroundImage: `url(${widget("storage.image", item.image)})`}}/> TODO rmv */}
                    <div className="bcg_image" style={{backgroundImage: `url(https://barok032.rs/prod-images/m/${item.id}.jpg)`}} />
                </div>
            </Link>
            <Link to={route("products.shopProduct", item)}>
                <div className="fw-bold mb-2" style={{minHeight: 48}}>
                    {item.name}
                </div>
                <div className="text-black-50">
                    <span className="fs-s"> Šifra: </span> {item.code} <br/>
                    <span className="fs-s"> Količina u paketu: </span> {item.komadaUPaketu} <br/>
                    <span className="fs-s"> Jedinica mere: </span> {item.jedinicaMere} <br/>
                    <span className="fs-s"> Stanje: </span> {item.qty < 100 ? item.qty : "100+"}
                </div>
            </Link>
            {widget("users.UserGuard",
                {children: <div className="mt-3 d-md-flex justify-content-between align-items-center">
                    <div className="fw-bold text-secondary fs-5">
                        {formatPrice(item.process_price)} {trans("currency")}
                    </div>

                    {widget("orders.ProductBagButton", {item})}
                </div>})}
        </div>

    )
}

export default ProductRender;