import {Link} from "react-router-dom";
import {route} from "../../../modulesCompiler";
import React from "react";
import {providerLogoutUser} from "../../userProvider";



function LeftMenu()   {
    const indexRoute = route("public.index");

    const logout = (e) => {
        e.preventDefault();
        providerLogoutUser();
        window.location.href = indexRoute;
    }

    return (
        <div className="bg-white p-3 rounded-3">
            <div className="">
                <Link to={route("users.dashboard")} className="p-2 d-inline-block">
                    Profil kupca
                </Link>
            </div>
            <div className="">
                <Link to={route("users.orders")} className="p-2 d-inline-block">
                    Istorija porudzbina
                </Link>
            </div>
            <div className="">
                <Link to={route("users.discount")} className="p-2 d-inline-block">
                    Rabat
                </Link>
            </div>
            <Link onClick={logout} to={route("users.dashboard")} className="p-2 d-inline-block">
                Izlogujte se
            </Link>

        </div>
    )
}
export default LeftMenu;