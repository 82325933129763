import {gql} from "@apollo/client";


export const USER_FRAGMENT = gql`
    fragment UserFragment on User {
        id 
        verified
        banned
        discount
        commercial
        name 
        lastname
        email
        phone
        firm_name
        firm_address
        firm_place
        firm_pib
        firm_number
        count_login_attempts
        last_login
    }
`;

export const GET_SELF_QUERY = "getUser";
export const GET_SELF = gql`
    ${USER_FRAGMENT}
    query {
        getUser {
            ...UserFragment
        }
    }
`

export const LOGIN_USER_QUERY = "loginUser";
export const LOGIN_USER = gql`
    mutation LoginUser($email: String!, $password: String!)  {
        loginUser(email: $email, password: $password) {
            token
            error
        }
    }
`

export const CREATE_USER_QUERY = "createUser";
export const CREATE_USER = gql`
    ${USER_FRAGMENT}
    mutation CreateUser($email: String!, $password: String!, $name: String!, $lastname: String!, $phone: String!, 
        $firm_name: String, $firm_address: String, $firm_place: String, $firm_pib: String, $firm_number: String)  {
        createUser(email: $email, password: $password, name: $name, lastname: $lastname, phone: $phone,
        firm_name: $firm_name, firm_address: $firm_address, firm_place: $firm_place, firm_pib: $firm_pib, firm_number: $firm_number) {
            ...UserFragment
        }
    }
`

export const UPDATE_USER = gql`
    ${USER_FRAGMENT}
    mutation UpdateUser($id: ID!, $name: String, $lastname: String, $phone: String,
        $firm_name: String, $firm_address: String, $firm_place: String, $firm_pib: String, $firm_number: String)  {
        updateUser(id: $id, name: $name, lastname: $lastname, phone: $phone,
            firm_name: $firm_name, firm_address: $firm_address, firm_place: $firm_place, firm_pib: $firm_pib, firm_number: $firm_number) {
            ...UserFragment
        }
    }
`
export const ADMIN_UPDATE_USER = gql`
    ${USER_FRAGMENT}
    mutation($id: ID!, $verified: Boolean, $banned: Boolean,
        $name: String, $lastname: String, $phone: String, $discount: Int, $commercial: Int,
        $firm_name: String, $firm_address: String, $firm_place: String, $firm_pib: String, $firm_number: String)  {
        adminUpdateUser(id: $id, verified: $verified, banned: $banned, name: $name, lastname: $lastname, phone: $phone,
            firm_name: $firm_name, firm_address: $firm_address, firm_place: $firm_place, firm_pib: $firm_pib, 
            firm_number: $firm_number, discount: $discount, commercial: $commercial) {
            ...UserFragment
        }
    }
`

export const GET_USERS_QUERY = "getUsers";
export const GET_USERS = gql`
    ${USER_FRAGMENT}
    query($first: Int, $page: Int, $search: String, $order: Int) {
        getUsers(first: $first, page: $page, search: $search, order: $order) {
            data {
                ...UserFragment
                basket
            }
            paginatorInfo {
                currentPage
                lastPage
                count
                total
            }
        }
    }
`

export const DELETE_USER = gql`
    ${USER_FRAGMENT}
    mutation ($id: ID!) {
        deleteUser(id: $id) {
            ...UserFragment
        }
    }
`

