import {widget} from "../../modulesCompiler";
import {seoKeywords, seoReset, seoTitle} from "../../../app/seoProvider";


const SeoMiddleware = ({ middleware, route, ...rest }) => {
    seoReset();
    if(route && route?.seo)   {
        const title =  widget("settings.useGetSetting", route.seo.key + "_seo_title");
        if(title?.value)   {
            seoTitle(title.value);
        }
        const keywords =  widget("settings.useGetSetting", route.seo.key + "_seo_keywords");
        if(keywords?.value)   {
            seoKeywords(keywords.value);
        }
        const description =  widget("settings.useGetSetting", route.seo.key + "_seo_description");
        if(description?.value)   {
            seoKeywords(description.value);
        }
    }
    return widget(middleware, {...rest})
};

export default SeoMiddleware;