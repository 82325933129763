import LeftMenu from "./LeftMenu";
import React from "react";


function Layout({children}) {

    return (
        <div className="container mt-5 mb-6">
            <div className="row">
                <div className="col-md-3">
                    <LeftMenu />
                </div>
                <div className="col-md-9">
                    {children}
                </div>
            </div>
        </div>
    )
}
export default Layout;