import {widget} from "../../../modulesCompiler";
import React from "react";
import {useQuery} from "@apollo/client";
import {GET_ORDER_BY_CODE, GET_ORDER_BY_CODE_QUERY} from "../../graphql/order";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import OrderRender from "../../widgets/OrderRender";


function OrderStatus(props) {
    const code = props.computedMatch?.params?.code;
    const thankYou = props.computedMatch?.params?.thankYou;
    const {data, loading, error} = useQuery(GET_ORDER_BY_CODE, {
        variables: {code}
    });
    if(loading) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    const order = data[GET_ORDER_BY_CODE_QUERY];
    if(!order)   {
        return <>
            {widget("public.Breadcrumbs", {links: [{trans: "STATUS"}]})}
            {widget("public.Error404")}
        </>
    }

    return  (
        <div>
            {widget("public.Breadcrumbs", {links: [{trans: "STATUS"}]})}
            <div className="container mb-6">
                {thankYou && thankYou === "thank-you" &&
                <div className="alert alert-success h4 text-center">
                    <div className="alert alert-success mt-3">
                        Uspešno poručeno. <br /> Hvala na poverenju!
                    </div>
                </div>}
                <OrderRender order={order} />
            </div>
        </div>
    )
}
export default OrderStatus;