import React from "react";
import {Link} from "react-router-dom";
import {ChevronRight} from "react-feather";
import {route, trans} from "../../modulesCompiler";


function Breadcrumbs({links}) {

    return (
        <div className="container weight_500 link_gray text-black-50 mt-4 mb-4 fs-s">
            <Link to={route("public.index")}>
                {trans("naslovna")}
            </Link>
            {links &&
            <>
                <ChevronRight strokeWidth={2} size={12} />
                {links.map((item, index) => {
                    return <React.Fragment key={index}>
                        {item.route
                            ? <Link to={item.route}>
                                {item.trans}
                            </Link>
                            : <span>{item.trans}</span>}
                        {((index+1) !== links.length) &&
                        <ChevronRight strokeWidth={2} size={12} />}
                    </React.Fragment>
                })}
            </>}
        </div>
    )
}
export default Breadcrumbs;