import React, {useState} from "react";
import {UPSERT_PRODUCT} from "../../graphql/product";
import CmsUpdateField from "../../../../app/CmsUpdateField";
import {route} from "../../../modulesCompiler";
import {Link} from "react-router-dom";
import {checkNoRabat} from "../../../../app/helpers";


const ProductItem = ({item, refetch}) => {
    const [edit] = useState(false);
    //const [deleteProduct] = useMutation(DELETE_PRODUCT);
    //const [productToTop] = useMutation(PRODUCT_TO_TOP);

    /*const deleteItem = () => {
        if(!window.confirm("Zelite da obrisete proizvod?"))  {
            return false;
        }
        deleteProduct({variables: {id: item.id}}).then(response => {
            toast.success("Obrisano!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }
    const toTop = () => {
        productToTop({variables: {id: item.id}}).then(response => {
            toast.success("Sačuvano!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }*/

    return (
        <div>
            <div className="d-flex" style={{opacity: item.active ? 1 : 0.3}}>
                <div className="flex-grow-1 d-flex">
                    <div className="me-3" style={{width: 100}}>
                        {/*<div className="bcg_image" style={{backgroundImage: `url(${widget("storage.image", item.image)})`}} /> TODO rmv*/}
                        <div className="bcg_image" style={{backgroundImage: `url(https://barok032.rs/prod-images/m/${item.id}.jpg)`}} />
                    </div>
                    <div>
                        {edit
                            ?  <CmsUpdateField
                                element="text" required={true}
                                id={item.id} MUTATION={UPSERT_PRODUCT}
                                name="name" value={item.name} />
                            : <>
                                <Link to={route("products.product", item)}>
                                    <h6>{item.name}</h6>
                                </Link>
                            </>}

                        {item.code &&
                        <div className="mb-3">#{item.code}</div>}
                        {item.brand &&
                        <div>{item.brand}</div>}

                        {item.vreme > 0 &&
                        <span className="bg-success fw-bold fs-s text-white mb-3 me-2 d-inline-block rounded-2 p-1 ps-2 pe-2">
                                NOVO
                            </span>}
                        {item.meniId === 2 &&
                        <span className="bg-danger fw-bold fs-s text-white mb-3 me-2 d-inline-block rounded-2 p-1 ps-2 pe-2">
                                AKCIJA
                            </span>}
                        {checkNoRabat(item.badges) &&
                        <span className="bg-danger fw-bold fs-s text-white mb-3 me-2 d-inline-block rounded-2 p-1 ps-2 pe-2">
                                0 RABAT
                            </span>}
                    </div>
                </div>
                {/*  TODO rmv
                 <div className="me-2" style={{width: 120}}>
                    <CmsUpdateField
                        element="checkbox"
                        id={item.id}
                        className="w-100"
                        MUTATION={UPSERT_PRODUCT}
                        name="active"
                        value={item.active}
                        onTrue="Deaktivirajte"
                        onFalse="Aktivirajte"
                    />
                    <button onClick={toTop} title="Prebacite proizvod na vrh." className="btn btn-light btn-sm w-100 mb-1">
                        <ChevronsUp size={16} /> Na vrh
                    </button>
                </div>
                <div style={{width: 130}}>
                    <button onClick={() => setEdit(!edit)}
                            className={`btn ${edit ? "btn-danger" : "btn-light"} btn-sm w-100 mb-2`}>
                        <Edit size={16} /> {edit ? "Zatvorite" : "Prepravite"}
                    </button>
                    <Link to={route("products.product", item)} className="btn btn-light btn-sm w-100 mb-2">
                        <Settings size={16} />  Detaljnije
                    </Link>
                    <button onClick={deleteItem} className="btn btn-light btn-sm w-100">
                        <Trash2 size={16} />  Obrisite
                    </button>
                </div>
                */}

            </div>
        </div>
    )
}

export default ProductItem;
