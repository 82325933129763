
export function server(path) {
    if(path.startsWith("/")) path = path.substring(1);
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return `http://127.0.0.1:5000/${path}`;
    }
    return `https://server.barok032.rs/${path}`;
}
export function graphQL() {
    return server("graphql");
}
export const urlQueryOptions = {
    page: {values: ["page"], integer: true, default: 1},
    order: {values: ["order"], integer: true, default: 1},
    search: {values: ["search"], array: true, default: ""},
}


