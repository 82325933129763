import {gql} from "@apollo/client";
import {PRODUCT_FRAGMENT} from "../../products/graphql/ProductFragment";


export const BASKET_FRAGMENT = gql`
    ${PRODUCT_FRAGMENT}
    fragment BasketFragment on Basket {
        id
        user_id
        product_id
        qty
        filters
        product {
            ...ProductFragment
        }
    }
`

export const GET_BASKET_QUERY = "getBasket";
export const GET_BASKET = gql`
    ${BASKET_FRAGMENT}
    query  {
        getBasket     {
            ...BasketFragment
        }
    }
`

export const CREATE_BASKET = gql`
    ${BASKET_FRAGMENT}
    mutation ($product_id: ID!, $qty: Int!)  {
        createBasket(product_id: $product_id, qty: $qty) {
            ...BasketFragment
        }
    }
`

export const UPDATE_BASKET = gql`
    ${BASKET_FRAGMENT}
    mutation ($id: ID!, $qty: Int!)  {
        updateBasket(id: $id, qty: $qty) {
            ...BasketFragment
        }
    }
`

export const DELETE_BASKET = gql`
    ${BASKET_FRAGMENT}
    mutation ($id: ID!)  {
        deleteBasket(id: $id) {
            ...BasketFragment
        }
    }
`
export const EMPTY_BASKET = gql`
    ${BASKET_FRAGMENT}
    mutation ($ids: [ID!]!)  {
        emptyBasket(ids: $ids) {
            ...BasketFragment
        }
    }
`