import {useQuery} from "@apollo/client";
import {GET_PRODUCT, GET_PRODUCT_QUERY} from "../../graphql/product";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {route, trans, widget} from "../../../modulesCompiler";
import {checkNoRabat, formatPrice} from "../../../../app/helpers";
import ProductsEmbla from "../../components/ProductsEmbla";
import {seoAll} from "../../../../app/seoProvider";


function ShopProduct(props)  {
    const id = props.computedMatch?.params?.id;
    const shopBreadcrumbs = {trans: trans("shop"), route: route("products.shop")};

    const { data, loading, error } =  useQuery(GET_PRODUCT, {
        variables: {id: id}
    });
    if(loading) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    const product = data[GET_PRODUCT_QUERY];

    if(!product)    {
        return <>
            {widget("public.Breadcrumbs", {links: [shopBreadcrumbs]})}
            {widget("public.Error404")}
        </>
    }
    seoAll(product.name);

    return <div>
        {widget("public.Breadcrumbs", {links: [shopBreadcrumbs,
                {trans: product.category?.name, route: route("products.shop", product.category)},
                {trans: product.name}]})}

        <div className="container mb-5">
            <div className="row g-md-5">
                <div className="col-md-5 d-flex">
                    {/*<div style={{flex: "100px 0 0"}}>
                        {product.images.map((item, index) => {
                            return <div key={item.id} className={`mb-2 border border-2 p-1 ${index === 0 ? "border-secondary" : ""}  me-3`}>
                            <img src={widget("storage.image", item)} alt={product.name} className="img-fluid" />
                            </div>
                        })}
                    </div>*/}
                    <div className="flex-grow-1 bg-white rounded-3 overflow-hidden p-3">
                        {/*<img src={widget("storage.image", product.image)} alt={product.name} className="img-fluid" /> TODO rmv */}
                        <img src={`https://barok032.rs/prod-images/b/${product.id}.jpg`} alt={product.name} className="img-fluid" />
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="bg-white rounded-3 overflow-hidden p-4">
                        <div>
                            {product.meniId === 2 &&
                            <span className="bg-danger fw-bold fs-s text-white mb-3 me-2 d-inline-block rounded-2 p-1 ps-2 pe-2">
                                AKCIJA
                            </span>}
                            {product.vreme > 0 &&
                            <span className="bg-success fw-bold fs-s text-white mb-3 me-2 d-inline-block rounded-2 p-1 ps-2 pe-2">
                                NOVO
                            </span>}
                            {checkNoRabat(product.badges) &&
                            <span title={product.badges[0].opis} className="bg-danger fw-bold fs-s  mb-3 me-2 d-inline-block text-white rounded-2 p-1 ps-2 pe-2">
                                0 RABAT
                            </span>}
                        </div>


                        <h1 className="oswald fw-bold h4 mb-3">{product.name}</h1>

                        {widget("users.UserGuard",
                            {children: <div className="border-bottom mb-3 pb-3 d-flex align-items-center justify-content-between">
                                    <div className="fw-bold text-secondary fs-5">
                                        {formatPrice(product.process_price)} {trans("currency")}
                                    </div>
                                    {widget("orders.ProductBagButton", {item: product, full: true})}
                                </div>})}


                        {product.description &&
                        <div className="mb-3"
                             dangerouslySetInnerHTML={{__html: product.description}} />}

                        <div className="text-black-50 mt-4">
                            <span className="fs-s"> Šifra: </span> {product.code} <br/>
                            <span className="fs-s"> Količina u paketu: </span> {product.komadaUPaketu} <br/>
                            <span className="fs-s"> Jedinica mere: </span> {product.jedinicaMere} <br/>
                            <span className="fs-s"> Stanje: </span> {product.qty < 100 ? product.qty : "100+"}
                        </div>

                    </div>

                </div>
            </div>
        </div>
        {product.connected.length > 0 &&
        <div className="container mb-6">
            <div className="mb-4">
                <h4 className="fw-bold mb-3">POVEZANI PROIZVODI</h4>
            </div>
            <ProductsEmbla products={product.connected} />
        </div>}
    </div>
}
export default ShopProduct;