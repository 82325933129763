import {useQuery} from "@apollo/client";
import {GET_ORDERS, GET_ORDERS_QUERY} from "../../graphql/order";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {widget} from "../../../modulesCompiler";
import OrderItem from "./OrderItem";


function Orders() {
    const {data, loading, error, refetch, previousData} = useQuery(GET_ORDERS, {
        pollInterval: 5000,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });
    if(loading && !previousData) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    let holder = data || previousData;
    const orders = holder ? holder[GET_ORDERS_QUERY].data : [];
    const paginatorInfo = holder ? holder[GET_ORDERS_QUERY].paginatorInfo : {};

    return (
        <div className="container">
            <div className="mb-3 text-end">
                {orders.length > 0 &&
                widget("cms.Pagination", {paginatorInfo: paginatorInfo, refetch: refetch})}
            </div>


            {orders.length === 0 &&
            <div className="p-5 text-center"> Prazna pretraga </div>}
            {orders.map(item => {
                return <div key={item.id} className="d-flex align-items-center bg-white mb-2 shadow-sm list_item">
                    <div className="flex-grow-1">
                        <OrderItem item={item} refetch={refetch} />
                    </div>
                </div>
            })}

            <div className="mb-3 text-end">
                {orders.length > 0 &&
                widget("cms.Pagination", {paginatorInfo: paginatorInfo, refetch: refetch})}
            </div>
        </div>
    )
}
export default Orders;