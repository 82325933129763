import {useQuery} from "@apollo/client";
import {GET_BLOCKS, GET_BLOCKS_QUERY} from "../graphql/blocks";
import Loader from "../../../app/Loader";
import GraphQLErrorMessage from "../../../app/GraphQLErrorMessage";
import React from "react";
import BannersCarousel from "../components/BannersCarousel";
import IndexSlider from "../components/IndexSlider";
import Banner from "../components/Banner";
import {widget} from "../../modulesCompiler";

function BlocksRender({code}) {
    const { data, loading, error } =  useQuery(GET_BLOCKS, {
        variables: {code: code}
    });
    if(loading) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    if(!data)   return null;
    const blocks = data[GET_BLOCKS_QUERY];

    return (
        <>
            {blocks.map(item => {
                if(!item.active) return null;
                return <div key={item.id} className="mb-6">
                    {item.title  &&
                    <div className="container mb-4">
                        <h4 className="fw-bold mb-3">{item.title}</h4>
                    </div>}
                    {item.type === 1 &&
                    <div className="container">
                        {item.banners.length === 1
                            ?  <div className="rounded-3 overflow-hidden">
                                <Banner banner={item.banners[0]} />
                            </div>
                            : <IndexSlider banners={item.banners} />}
                    </div>}
                    {item.type === 2 &&
                    <div className="container">
                        <BannersCarousel slides={item.banners} />
                    </div>}
                    {item.type === 3 &&
                    <div className="container">
                        {widget("products.ProductsCarousel", {products: item.products})}
                    </div>}
                    {item.type === 4 &&
                    <div className="container">
                        {widget("products.LoadProductsCarousel",
                            {variables: {
                                category: item.category?.category_id || undefined,
                                first: item.category?.max || undefined,
                                price: item.category?.price ? parseFloat(item.category?.price) : undefined,
                            }})}
                    </div>}
                </div>
            })}

        </>
    )
}
export default BlocksRender;