import {gql} from "@apollo/client";
import {STORAGE_FRAGMENT} from "../../storage/graphql/StorageFragment";


export const PRODUCT_FRAGMENT = gql`
    ${STORAGE_FRAGMENT}
    fragment ProductFragment on Product {
        id
        active
        name
        code
        brand
        price
        discount_price
        process_price
        action
        new
        description
        qty
        image {
            ...StorageFragment
        }

        meniId
        badges {
            id
            ime
            opis
            slicica
        }
        vreme
        pdv
        komadaUPaketu
        jedinicaMere
    }
`;

