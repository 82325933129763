import Select from "react-select";
import React, {useContext} from "react";
import {customSelectStyles} from "../../../app/helpers";
import LangContext from "../contexts/LangContext";
import {options} from "../config";


function DropDown() {
    const {lang, setLang} = useContext(LangContext);

    const handleChange = (prop) => {
        setLang(prop.id);
    }

    return (
        <div style={{width: 100}}>
            <Select
                onChange={handleChange}
                styles={customSelectStyles}
                isSearchable={false}
                options={options}
                defaultValue={options[lang]} />
        </div>
    )
}
export default DropDown;