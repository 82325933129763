import {Minus, Plus, ShoppingBag} from "react-feather";
import {useContext} from "react";
import BasketContext from "../contexts/BasketContext";


function ProductBagButton({item, full}) {
    const {basket, add, remove, update} = useContext(BasketContext);
    const find = basket.find(f => f.product.id === item.id);

    const addToBasket = () => {
        add(item, 1);
    }
    const onChange = (e) => {
        const value = e.target.value;
        updateBasket(value);
    }
    const onPlus = () => {
        let qty = parseInt(find.qty) + 1;
        if(qty > item.qty)  {
            alert(`Maksimalno na stanju: ${item.qty}`);
            qty = item.qty;
        }
        updateBasket(qty);
    }
    const onMinus = () => {
        const qty = parseInt(find.qty) - 1;
        if(qty === 0)   {
            remove(item.id);
            return false;
        }
        updateBasket(qty);
    }
    const updateBasket = (qty) => {
        if(isNaN(qty))    {
            alert("Mora biti broj.");
            return false;
        }
        update(item.id, qty);
    }

    return (
        <>
            {!find
                ?  <button onClick={addToBasket}
                    className="btn btn-light">
                    {full && <span className="fw-bold me-2">DODAJTE U KORPU</span>} <ShoppingBag />
                </button>
                : <div className="input-group product_qty_btn">

                    <div className="input-group-prepend">
                        <button onClick={onMinus} className="btn btn-dark btn_left shadow-none" type="button">
                            <Minus size={14} />
                        </button>
                    </div>
                    <input type="text" value={find ? find.qty : 0} onChange={onChange}
                           className="form-control bg-dark border-dark text-white fw-bold" placeholder=""  />
                    <div className="input-group-append">
                        <button onClick={onPlus} className="btn btn-dark btn_right shadow-none" type="button">
                            <Plus size={14} />
                        </button>
                    </div>
                </div>}
        </>

    )
}
export default ProductBagButton;