import CmsUpdateField from "../../../app/CmsUpdateField";
import {useContext} from "react";
import SettingsContext from "../contexts/SettingsContext";
import {UPSERT_SETTING} from "../graphql/setting";


function FieldUpdate({field, title})    {
    const {getSetting, refetch} = useContext(SettingsContext);
    const setting = getSetting(field);

    return (
        <div>
            <div className="mb-1">{title}</div>
            <CmsUpdateField
                element="textarea"
                id={setting.id}
                MUTATION={UPSERT_SETTING}
                name="value"
                refetch={refetch}
                nullable={true}
                extend={{field: field}}
                value={setting.value} />
        </div>
    )
}
export default FieldUpdate;