import React from "react";


function Pagination({paginatorInfo, url}) {
    const {currentPage, lastPage} = paginatorInfo;
    if(lastPage === 1)  return null;

    const change = (e) => {
        const page = parseInt(e.target.value);
        url("page", page);
        window.scrollTo({ top: 200, behavior: 'smooth' });
    }

    return <div className="">
        {((currentPage - 2) > 1) &&
        <>
            <button
                name="page" value="1"
                onClick={change}
                className={(currentPage === 1) ? "btn btn-primary shadow-none me-2" : "btn btn-outline-primary bg-white text-primary shadow-none me-2"}>
                1
            </button>
            {((currentPage - 2) !== 1) && <span> ... </span>}
        </>
        }
        {[currentPage-2, currentPage-1, currentPage, currentPage+1, currentPage+2].map((item) => {
            if(item < 1) return "";
            if(item > lastPage) return "";
            return <button key={item}
                           name="page" value={item}
                           className={(currentPage === item) ? "btn btn-primary shadow-none me-2" : "btn btn-outline-primary text-primary bg-white shadow-none me-2"}
                           onClick={change}>
                {item}
            </button>
        })}
        {((currentPage + 2) < lastPage) &&
        <>
            {((currentPage + 2) !== lastPage) &&  <span> ... </span>}
            <button
                name="page" value={lastPage}
                className={(currentPage === lastPage) ? "btn btn-primary shadow-none me-2" : "btn btn-outline-primary text-primary bg-white shadow-none me-2"}
                onClick={change}>
                {lastPage}
            </button>
        </>
        }
    </div>
}

export default Pagination;
