import {useQuery} from "@apollo/client";
import {GET_ORDERS, GET_ORDERS_QUERY} from "../../graphql/order";


function UnseenOrderAppend() {
    const {data} = useQuery(GET_ORDERS, {
        variables: {seen: null},
        pollInterval: 5000,
    });
    if(!data) return null;
    const paginatorInfo = data ? data[GET_ORDERS_QUERY].paginatorInfo : null;
    return (
        <div className="bg-danger rounded-circle fw-bold text-center" style={{width: 24, height: 24}}>
            {paginatorInfo.total}
        </div>
    )
}
export default UnseenOrderAppend;