import CategoryItem from "./CategoryItem";
import {useQuery} from "@apollo/client";
import {GET_CATEGORIES, GET_CATEGORIES_QUERY} from "../../graphql/categories";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import React from "react";


function Categories({close}) {
    const { data, loading, error } =  useQuery(GET_CATEGORIES, {
        //variables: {parent: null} TODO rmv
    });
    if(loading) return null;
    if(error)   return <GraphQLErrorMessage error={error} />
    if(!data)   return null;
    const categories = data[GET_CATEGORIES_QUERY];

    return (
        <div>
            <div className="text-black-50 mb-3 fw-bold ms-4">Kategorije</div>
            <div className="cat_menu">
                    {categories.map(item => {
                        return <div key={item.id}>
                            <CategoryItem item={item} close={close} />
                        </div>
                    })}

            </div>
        </div>
    )
}
export default Categories;

