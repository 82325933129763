import {useState} from "react";
import {ChevronDown, ChevronUp} from "react-feather";
import {widget} from "../../../modulesCompiler";

function PageItem({item})    {
    const [open, setOpen] = useState(false);

    return (
        <div className="bg-white rounded-3 mb-2 list_item">
            <div onClick={() => setOpen(!open)}  className="d-flex pointer p-2">
                {open ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
                <h6 className="mb-0 ms-2">{item.title}</h6>
            </div>

            {open &&
            <div className="p-3">
                <div className="mb-3">
                    {widget("settings.FieldUpdate", {field: `${item.key}_seo_title`, title: "SEO TITLE"})}
                </div>
                <div className="mb-3">
                    {widget("settings.FieldUpdate", {field: `${item.key}_seo_keywords`, title: "SEO KEYWORDS"})}
                </div>
                {widget("settings.FieldUpdate", {field: `${item.key}_seo_description`, title: "SEO DESCRIPTION"})}
            </div>}
        </div>
    )
}
export default PageItem;