import {BrowserRouter, Switch} from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import {routes, widget} from "../../modulesCompiler";


function GlobalRouterWrapper()    {
    const last = [];
    return (
        <BrowserRouter>
            <ScrollToTop/>
            <Switch>
                {Object.values(routes).map((route, index) => {
                    if(typeof route.path === 'string' && route.path.endsWith("*"))        {
                        last.push(route);
                        return null;
                    }
                    return widget("seo.SeoMiddleware", {
                        key: index,
                        exact: true,
                        route: route,
                        path: route.path,
                        component: route.component,
                        middleware: route.middleware
                    });
                })}
                {last.map((route, index) => {
                    return widget("seo.SeoMiddleware", {
                        key: index,
                        exact: true,
                        route: route,
                        path: route.path,
                        component: route.component,
                        middleware: route.middleware
                    });
                })}
            </Switch>
        </BrowserRouter>
    )
}
export default GlobalRouterWrapper;