import GraphQLErrorField from "./GraphQLErrorField";
import {useEffect, useState} from "react";
import {useMutation} from "@apollo/client";
import GraphQLErrorMessage from "./GraphQLErrorMessage";
import toast from "react-hot-toast";

function CmsUpdateField({id, element, name, value, checkValue, MUTATION, required, classes,
             placeholder, nullable, className, onTrue, onFalse, price,  refetch, type, extend = {}}) {

    const defaultValue = value || "";
    const [field, setField] = useState(defaultValue);
    const [update, {error, loading}] = useMutation(MUTATION);

    useEffect(() => {
        const defaultValue = value || "";
        setField(defaultValue);
    }, [value]);

    const submit = (e) => {
        e.preventDefault();
        save(field);
    }
    const submitCheckbox = (e) => {
        let value = e.target.checked ? true : null;
        if(checkValue)  {
            value =  e.target.checked ? checkValue : null;
        }
        save(value);
        setField(value);
    }
    const save = (value) => {
        if(nullable && (value === "" || !value))    {
            value = null;
        }
        if(price && value)        {
            value = parseFloat(value.replace(" ", "").replace(",", "."));
        }
        if(type === "number")   {
            value = parseInt(value);
        }
        let form = {id: id, [name]: value};
        if(!id) {
            form = {...extend, [name]: value};
        }
        console.log(form)
        update({variables: form}).then(response => {
            if(refetch) {
                refetch();
            }
            toast.success("Sačuvano!");
        }).catch(error => {
            console.log(error.message);
        });
    }
    const text = () => {
        if(element !== "text") return null;
        return  <>
            <input onChange={(e) => setField(e.target.value)}
                       type={type || "text"} name={name} value={field} placeholder={placeholder}
                       className="form-control" required={required} />
            {submitButton()}
            </>
    }
    const textarea = () => {
        if(element !== "textarea") return null;
        return  <>
            <textarea onChange={(e) => setField(e.target.value)}
                   name={name} value={field} placeholder={placeholder}
                   className="form-control" required={required} />
            {submitButton()}
        </>
    }
    const checkbox = () => {
        if(element !== "checkbox") return null;
        let classs = "btn-light btn-sm ";
        if(classes) {
            classs = classes;
        }
        return <label className={`btn ${classs}`}>
            <input type="checkbox" value={checkValue || 1} onChange={submitCheckbox}
                   checked={checkValue ? field === checkValue : !!field} /><span />
            <span> {field ? onTrue : onFalse} </span>
        </label>
    }
    const submitButton = () => {
        if(field === defaultValue) return null;
        return <div className="input-group-append">
            <button disabled={loading} type="submit" className="btn btn-success"> Sačuvajte </button>
        </div>
    }
    return (
        <form onSubmit={submit} className={className ? className : ""}>
            <div className="input-group">
                {checkbox()}
                {text()}
                {textarea()}
            </div>
            <GraphQLErrorField error={error} field={name} />
            <GraphQLErrorMessage error={error} />
        </form>
    )
}
export default CmsUpdateField;