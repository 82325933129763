import BasketDrawerContext from "../contexts/BasketDrawerContext";
import {useContext} from "react";
import {ShoppingBag, X} from "react-feather";
import Drawer from "rc-drawer";
import {formatPrice} from "../../../app/helpers";
import {route, trans, widget} from "../../modulesCompiler";
import BasketContext from "../contexts/BasketContext";
import {Link} from "react-router-dom";
import ProductBagButton from "./ProductBagButton";


function BasketDrawer()    {
    const {open, setOpen} = useContext(BasketDrawerContext);
    const {basket, total, empty, removeByBasket} = useContext(BasketContext);
    const currency = trans("currency");

    return <Drawer
        open={open}
        wrapperClassName="drawer_full"
        onClose={() => setOpen(false)}
        handler={null}
        level={null} height="100%" placement="bottom">

        <div className="h-100 d-flex flex-column justify-content-between">
            <div className="row m-0 justify-content-center">
                <div className="col-md-5">
                    <div className="mt-2 mb-2 mt-md-4 mb-md-4 d-flex justify-content-between align-items-center">
                        <div className="fw-bold h4">Korpa</div>
                        <button onClick={() => setOpen(false)} className="btn btn-link">
                            <X />
                        </button>
                    </div>
                </div>
            </div>
            <div className="bg-light pt-3 pb-3">
                <div className="row m-0 justify-content-center">
                    <div className="col-md-5 d-flex justify-content-between">
                        <div> Ukupno: </div>
                        <div className="fw-bold"> {formatPrice(total())} {trans("currency")} </div>
                    </div>
                </div>
            </div>

            <div className="flex-grow-1" style={{overflowY: "auto"}}>
                <div className="row m-0 justify-content-center">
                    <div className="col-md-5 mt-3 mb-5">
                        {basket.length === 0
                            ? <div className="h5 text-center">
                                <ShoppingBag size={34} />
                                <div className="mt-2">
                                    Korpa je prazna.
                                </div>
                            </div>
                            : <div>
                                {basket.map(item => {
                                    const product = item.product;
                                    return <div key={item.id} className="d-md-flex align-items-center pb-3 mb-3 border-bottom">
                                        <div className="mt-2 d-flex align-items-center flex-grow-1 fw-bold">
                                            <div className="me-3" style={{flex: "140px 0 0"}}>
                                                {/*<div className="bcg_image" style={{backgroundImage: `url(${widget("storage.image", product.image)})`}} />*/}
                                                <div className="bcg_image" style={{backgroundImage: `url(https://barok032.rs/prod-images/m/${product.id}.jpg)`}} />
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="text-black-50 mb-2">
                                                    {product.name}
                                                </div>
                                                <div>
                                                    {formatPrice(product.process_price)} {currency}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="fw-bold mb-2 text-end">
                                                {formatPrice(product.process_price * item.qty)} {currency}
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <ProductBagButton item={product} />
                                            </div>
                                            <div className="mt-2 text-end">
                                                <button onClick={() => removeByBasket(item.id)} className="btn btn-light btn-sm shadow-sm">
                                                    <X /> Uklonite
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>}
                    </div>
                </div>
            </div>


            <div className="row m-0 justify-content-center mb-4">

                    {widget("users.UserGuard", {
                        children: <div className="col-md-5">
                            <div className="mb-3 row g-2">
                            <div className="col-6">
                                <button onClick={() => setOpen(false)} className="btn btn-light shadow-sm border fw-bold fs-s w-100">
                                    Nastavite kupovinu
                                </button>
                            </div>
                            <div className="col-6">
                                <button onClick={empty} className="btn btn-light shadow-sm border fw-bold fs-s w-100">
                                    Ispraznite korpu
                                </button>
                            </div>
                        </div>
                            <Link onClick={() => setOpen(false)} to={route("orders.checkout")}
                                  className="btn btn-secondary pt-2 pb-2 fw-bold w-100 shadow-lg">
                                Završite porudžbinu
                            </Link>
                        </div>
                    })}

            </div>
        </div>

    </Drawer>
}
export default BasketDrawer;