import {useState} from "react";
import GraphQLErrorField from "../../../../app/GraphQLErrorField";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {route} from "../../../modulesCompiler";
import {useMutation} from "@apollo/client";
import {CREATE_USER} from "../../graphql/user";
import {Link} from "react-router-dom";
import {User} from "react-feather";


function Register() {
    const defaultForm = {name: "", lastname: "", email: "", phone: "", password: "",
        firm_name: null, firm_address: null, firm_place: null, firm_pib: null, firm_number: null}
    const [form, setForm] = useState(defaultForm);

    const [passwordRepeat, setPasswordRepeat] = useState("");
    const [passwordRepeatError, setPasswordRepeatError] = useState("");
    const [registerError, setRegisterError] = useState("");
    const [registerSuccess, setRegisterSuccess] = useState("");
    const [createMutation, {error, loading}] = useMutation(CREATE_USER);

    const changeField = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        if(value === "")    value = null;
        setForm({...form, [name]: value});
    }

    const changePasswordRepeat = (e) => {
        setPasswordRepeat(e.target.value);
        if (!form.password.startsWith(e.target.value)) {
            setPasswordRepeatError("Ne poklapa se ponovljena lozinka.");
        } else {
            setPasswordRepeatError("");
        }
    }

    const submit = (e) => {
        e.preventDefault();
        setRegisterError("");
        if (form.password !== passwordRepeat) {
            setPasswordRepeatError("Ne poklapa se ponovljena lozinka.");
            return false;
        }
        createMutation({variables: form}).then(response => {
            setRegisterSuccess("Nalog je kreiran i biće aktivan čim ga odobrimo.");
            setForm(defaultForm);
        }).catch(({graphQLErrors}) => {
            console.log(graphQLErrors)
        });
    }

    return <div className="container mt-6 mb-6">
        <form onSubmit={submit}>

            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="bg-white p-3 pb-4 rounded-3">
                        <h4 className="mb-4"> Registrujte nalog </h4>
                        <div className="row">
                            <div className="col-md-6">
                                <label className="d-block mb-3">
                                    Ime:
                                    <input value={form.name} name="name" onChange={changeField}
                                           type="text" className="form-control" required={true} autoComplete="off"/>
                                </label>
                                <GraphQLErrorField error={error} field="name"/>

                                <label className="d-block mb-3">
                                    Prezime:
                                    <input value={form.lastname} name="lastname" onChange={changeField}
                                           type="text" className="form-control" required={true} autoComplete="off"/>
                                </label>
                                <GraphQLErrorField error={error} field="lastname"/>

                                <label className="d-block mb-3">
                                    Email:
                                    <input value={form.email || ""} name="email" onChange={changeField}
                                           type="email" className="form-control" required={true} autoComplete="off"/>
                                </label>
                                <GraphQLErrorField error={error} field="email"/>

                                <label className="d-block mb-3">
                                    Telefon:
                                    <input value={form.phone || ""} name="phone" onChange={changeField}
                                           type="text" className="form-control" required={true} autoComplete="off"/>
                                </label>
                                <GraphQLErrorField error={error} field="phone"/>

                                <label className="d-block mb-3">
                                    Lozinka:
                                    <input value={form.password || ""} name="password" onChange={changeField}
                                           type="password" className="form-control" required={true} autoComplete="off"/>
                                </label>
                                <GraphQLErrorField error={error} field="password"/>

                                <label className="d-block mb-3">
                                    Ponovite lozinku:
                                    <input value={passwordRepeat} onChange={changePasswordRepeat} autoComplete="off"
                                           type="password" className="form-control" required={true}/>
                                </label>
                                {passwordRepeatError &&
                                <div className="alert alert-danger mt-2">{passwordRepeatError}</div>}
                            </div>

                            <div className="col-md-6">
                                <label className="d-block mb-3">
                                    Naziv firme:
                                    <input value={form.firm_name || ""} name="firm_name" onChange={changeField}
                                           type="text" className="form-control" autoComplete="off"/>
                                </label>
                                <GraphQLErrorField error={error} field="firm_name"/>

                                <label className="d-block mb-3">
                                    Adresa firme:
                                    <input value={form.firm_address || ""} name="firm_address" onChange={changeField}
                                           type="text" className="form-control" autoComplete="off"/>
                                </label>
                                <GraphQLErrorField error={error} field="firm_address"/>

                                <label className="d-block mb-3">
                                    Mesto firme:
                                    <input value={form.firm_place || ""} name="firm_place" onChange={changeField}
                                           type="text" className="form-control" autoComplete="off"/>
                                </label>
                                <GraphQLErrorField error={error} field="firm_place"/>

                                <label className="d-block mb-3">
                                    PIB:
                                    <input value={form.firm_pib || ""} name="firm_pib" onChange={changeField}
                                           type="text" className="form-control" autoComplete="off"/>
                                </label>
                                <GraphQLErrorField error={error} field="firm_pib"/>

                                <label className="d-block mb-3">
                                    Matični broj:
                                    <input value={form.firm_number || ""} name="firm_number" onChange={changeField}
                                           type="text" className="form-control" autoComplete="off"/>
                                </label>
                                <GraphQLErrorField error={error} field="firm_number"/>
                            </div>
                        </div>

                        <button disabled={loading} className="btn btn-secondary shadow w-100" type="submit">
                            Registrujte se
                        </button>

                        <GraphQLErrorMessage error={error}/>
                        {registerError &&
                        <div className="alert alert-danger mt-2">{registerError}</div>}
                        {registerSuccess &&
                        <div className="alert alert-success mt-2">{registerSuccess}</div>}

                        <Link to={route("users.login")} className="btn btn-white shadow w-100 mt-3">
                            <User/> Ulogujte se
                        </Link>
                    </div>
                </div>
            </div>
        </form>
    </div>
}

export default Register;