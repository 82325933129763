import React, {useState} from "react";
import GoogleMapReact from "google-map-react";

function Map() {
    const [coords] = useState({lat: 43.909951934942136, lng: 20.400138088352907});

    const renderMarkers = (map, maps) => {
        return new maps.Marker({
            position: coords,
            map,
        });
    };

    return (
        <div style={{height: 400}}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyBEiSYtxrrCDlI6cobIM1bu81stEhA79hs"}}
                defaultCenter={coords}
                defaultZoom={14}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}>

            </GoogleMapReact>
        </div>
    )
}
export default Map;