import OrderRender from "../../widgets/OrderRender";
import {useMutation} from "@apollo/client";
import {useEffect} from "react";
import {UPDATE_ORDER} from "../../graphql/order";
import CmsUpdateField from "../../../../app/CmsUpdateField";


function Order({item}) {
    const [update] = useMutation(UPDATE_ORDER);
    useEffect(() => {
        if(!item.seen) {
            update({variables: {id: item.id, seen: true}});
        }
    }, [item, update]);

    return  (
        <div className="m-3 mb-5">
            <h3 className="mb-4 text-center">Porudžbina #{item.code} </h3>

            <div className="mb-3">
                <CmsUpdateField
                    element="checkbox"
                    id={item.id}
                    MUTATION={UPDATE_ORDER}
                    name="status"
                    classes="btn-light"
                    nullable={true}
                    value={item.status}
                    onTrue="PORUDŽBINA JE OBRAĐENA"
                    onFalse="PORUDŽBINA JE NEOBRAĐENA" />
            </div>

            <OrderRender order={item} />
        </div>

    )
}
export default Order;